'use-client';
import * as React from 'react';
import type { KeyboardEvent } from 'react';
import classNames from 'clsx';
import {
  formatClassNames,
  keyCodes,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import { useGesture } from '@wix/thunderbolt-elements/src/providers/useGesture/useGesture';
import type { SliderGallerySkinProps } from '../SliderGallery.types';
import SliderGalleryItem from '../../SliderGalleryItem/viewer/SliderGalleryItem';
import { TestIds, navButtonsDescriptionElementId } from '../constants';
import { generateItemIndexString } from '../../commons/itemUtils';
import semanticClassNames from '../WIP_SliderGallery.semanticClassNames';

const emptyFn = () => {};

const SliderGallery: React.FC<SliderGallerySkinProps> = props => {
  const {
    id,
    className,
    customClassNames = [],
    skinsStyle,
    skinsItemStyle,
    items,
    imageOnClickAction,
    openImageZoom = () => {},
    translations,
    aspectRatio,
    onItemClicked,
    keepImageAspectRatio,
    SkinNavButton,
    play = emptyFn,
    pause = emptyFn,
    onMouseEnter,
    onMouseLeave,
    playOnHover,
    playInLoop,
    getPlaceholder,
  } = props;

  const [isPressed, setIsPressed] = React.useState<
    Record<'left' | 'right', boolean>
  >({
    right: false,
    left: false,
  });

  const handleKeyDown = (direction: 'left' | 'right', event: KeyboardEvent) => {
    if (event.keyCode === keyCodes.enter && !isPressed[direction]) {
      setIsPressed(currentIsPressed => ({
        ...currentIsPressed,
        [direction]: true,
      }));
      callPlay(direction);
    }
  };

  const handleKeyUp = (direction: 'left' | 'right') => {
    if (isPressed[direction]) {
      setIsPressed(currentIsPressed => ({
        ...currentIsPressed,
        [direction]: false,
      }));
      pause(id);
    }
  };

  const galleryRef = React.useRef<HTMLDivElement>(null);
  const visibleItemsContainerRef = React.useRef<HTMLDivElement>(null);
  const allItemsContainerRef = React.useRef<HTMLDivElement>(null);

  const callPlay = (direction: 'left' | 'right') =>
    play(
      id,
      direction,
      visibleItemsContainerRef.current,
      allItemsContainerRef.current,
    );

  useGesture('onSwipeLeft', () => callPlay('right'), galleryRef);
  useGesture('onSwipeRight', () => callPlay('left'), galleryRef);

  const itemsWithOriginalIndex = items.map((item, index) => ({
    item,
    originalIndex: index,
  }));

  const totalItems = playInLoop
    ? [...itemsWithOriginalIndex, ...itemsWithOriginalIndex]
    : [...itemsWithOriginalIndex];

  const itemsToShow = totalItems.map(({ item, originalIndex }, index) => {
    const itemClickHandler = () => {
      onItemClicked?.({
        itemIndex: originalIndex,
        type: 'itemClicked',
      });
    };
    item.image.getPlaceholder = getPlaceholder;

    return (
      <SliderGalleryItem
        key={`item-${index}`}
        extraClassNames={{ root: skinsStyle.item }}
        skinsStyle={skinsItemStyle}
        imageOnClickAction={imageOnClickAction}
        itemId={generateItemIndexString(originalIndex, id)}
        openImageZoom={(dataId: string) => openImageZoom(dataId, id)}
        itemClickHandler={itemClickHandler}
        translations={translations}
        aspectRatio={aspectRatio}
        keepImageAspectRatio={keepImageAspectRatio}
        {...item}
      />
    );
  });

  const navButtonsDescribedById = `${id}-${navButtonsDescriptionElementId}`;

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(
        skinsStyle.root,
        className,
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
      role="region"
      aria-label={translations.galleryAriaLabel}
      ref={galleryRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <p
        id={navButtonsDescribedById}
        className={skinsStyle.playButtonsDescription}
      >
        {translations.playButtonAriaLabel}
      </p>
      <SkinNavButton
        ariaLabel={translations.playBackwardAriaLabel!}
        extraClassName={skinsStyle.swipeLeftHitArea}
        direction="left"
        ariaPressed={isPressed.left ? 'true' : 'false'}
        describedById={navButtonsDescribedById}
        {...(playOnHover
          ? {
              mouseEnter: () => callPlay('left'),
              mouseLeave: () => pause(id),
              keyDown: event => handleKeyDown('left', event),
              keyUp: () => handleKeyUp('left'),
            }
          : { pointerDown: () => callPlay('left') })}
      />
      <SkinNavButton
        ariaLabel={translations.playForwardAriaLabel!}
        extraClassName={skinsStyle.swipeRightHitArea}
        direction="right"
        ariaPressed={isPressed.right ? 'true' : 'false'}
        describedById={navButtonsDescribedById}
        {...(playOnHover
          ? {
              mouseEnter: () => callPlay('right'),
              mouseLeave: () => pause(id),
              keyDown: event => handleKeyDown('right', event),
              keyUp: () => handleKeyUp('right'),
            }
          : { pointerDown: () => callPlay('right') })}
      />
      <div
        data-testid={TestIds.visibleItemsContainer}
        className={skinsStyle.visibleItemsContainer}
        ref={visibleItemsContainerRef}
        aria-live="polite"
      >
        <div
          id={`items_${id}`}
          data-testid={TestIds.allItemsContainer}
          className={skinsStyle.allItemsContainer}
          ref={allItemsContainerRef}
        >
          {itemsToShow}
        </div>
      </div>
      <div className={skinsStyle.counter} />
      <div className={skinsStyle.autoplay} />
    </div>
  );
};

export default SliderGallery;
