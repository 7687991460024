import SliderGallery_SliderGalleryCircleSkinComponent from '../components/NativeGalleries/SliderGallery/viewer/skinComps/SliderGalleryCircleSkin/SliderGalleryCircleSkin.skin';


const SliderGallery_SliderGalleryCircleSkin = {
  component: SliderGallery_SliderGalleryCircleSkinComponent
};


export const components = {
  ['SliderGallery_SliderGalleryCircleSkin']: SliderGallery_SliderGalleryCircleSkin
};

